<template>
  <a-layout style="padding: 0; height: 100%; overflow: hidden">
    <a-layout-content
      style="
        background-color: #fff;
        line-height: 60px;
        margin: 0;
        width: 100%;
        height: 60px;
      "
    >
      <a-modal
        okText="确定"
        @ok="onout"
        :closable="false"
        :maskClosable="false"
        :visible="logvisible"
        :cancel-button-props="{ props: { disabled: true } }"
      >
        登录已失效，请前往重新登陆
      </a-modal>
      <div class="Enter-top">
        <div>
          <a-breadcrumb style="margin: 18px 0">
            <a-breadcrumb-item>资产管理</a-breadcrumb-item>
            <a-breadcrumb-item>项目管理</a-breadcrumb-item>
          </a-breadcrumb>
        </div>
        <div v-if="visible == ''" class="Enter-top-right">
          <a-button
            v-show="permissionlist.indexOf('/property/project:新增') > -1"
            style="margin: 0 15px"
            @click.prevent="showDrawer"
            type="primary"
          >
            新增项目
          </a-button>
          <a-button
            v-show="permissionlist.indexOf('/property/project:删除') > -1"
            type="danger"
            @click.prevent="onDetele"
          >
            删除
          </a-button>
        </div>
        <div v-else>
          <a-icon
            @click="onClose"
            style="
              width: 30px;
              margin-right: 5px;
              cursor: pointer;
              font-size: 25px;
            "
            type="rollback"
          />
        </div>
      </div>
    </a-layout-content>
    <a-layout
      v-if="visible == false"
      style="padding: 24px; height: 100%; overflow: auto"
    >
      <a-layout-content
        style="
          background-color: #fff;
          line-height: 60px;
          margin: 0;
          width: 100%;
        "
      >
        <a-form
          style="padding: 0 24px"
          class="ant-advanced-search-form"
          :form="fom"
          @submit="handleSearch"
        >
          <a-row class="flex-d-row" style="flex-wrap: wrap" :gutter="24">
            <a-col v-for="(i, index) in textlist" :key="index">
              <a-form-item style="margin-bottom: 0" :label="i">
                <a-input
                  style="width: 178px"
                  v-decorator="[
                    `${i}`,
                    {
                      rules: [],
                    },
                  ]"
                  :placeholder="'请输入' + i"
                />
              </a-form-item>
            </a-col>
            <a-col class="a-col-top">
              <a-button type="primary" html-type="submit"> 搜索 </a-button>
              <a-button :style="{ marginLeft: '8px' }" @click="handleReset">
                清空
              </a-button>
              <!-- <a
                :style="{ marginLeft: '8px', fontSize: '12px' }"
                @click="toggle"
              >
                更多 <a-icon :type="expand ? 'up' : 'down'" />
              </a> -->
            </a-col>
          </a-row>
        </a-form>
      </a-layout-content>
      <a-layout-content
        :style="{
          background: '#fff',
          padding: '24px',
          margin: 0,
        }"
      >
        <div>
          <span v-if="packagelist.packages.length > 0" class="packages"
            >当前为{{
              packagelist.packages.length > 0
                ? packagelist.packages[0].packageNameSnapshot +
                  '等' +
                  packagelist.packages.length +
                  '个套餐'
                : packagelist.packages[0].packageNameSnapshot
            }}-</span
          >项目总量:
          <span class="packages">{{
            packagelist.total + packagelist.usage
          }}</span
          >-已添加: <span class="packages">{{ packagelist.usage }}</span> -剩余:
          <span class="packages">{{
            packagelist.total - packagelist.total
          }}</span>
        </div>
        <!-- 表格 -->
        <div class="search-wrap">
          <a-table
            :customRow="handleClickRow"
            :row-selection="rowSelection"
            rowKey="id"
            :pagination="false"
            :columns="columns"
            :data-source="projectlist"
            :rowClassName="rowClassName"
          >
            <span slot="status" slot-scope="text, record">
              <a-switch
                v-if="permissionlist.indexOf('/property/project:修改') == -1"
                :checked="text == 0 ? true : false"
                default-checked
                disabled="disabled"
                @change="onchangestate(record)"
              />
              <a-switch
                v-else
                :checked="text == 0 ? true : false"
                default-checked
                @change="onchangestate(record)"
              />
            </span>
            <template slot="action" slot-scope="text, record">
              <a
                v-if="permissionlist.indexOf('/property/project:修改') > -1"
                @click="onEdit(record)"
                >编辑</a
              >
              <a v-else>暂无权限</a>
            </template>
          </a-table>
          <a-pagination
            v-model="params.current"
            :page-size="params.pageSize"
            show-quick-jumper
            :default-current="2"
            :total="total"
            @change="onpagesize"
          />
        </div>
      </a-layout-content>
    </a-layout>
    <!-- 编辑页 -->
    <a-layout v-else style="padding: 24px; height: 100%; overflow: auto">
      <a-layout-content
        :style="{
          background: '#fff',
          margin: 0,
        }"
      >
        <div class="content-title">
          <span>基础信息</span>
        </div>
        <div>
          <a-form-model
            style="flex-wrap: wrap"
            class="content-body flex-d-row"
            ref="ruleForm"
            :model="form"
            :rules="rules"
            :label-col="labelCol"
            :wrapper-col="wrapperCol"
          >
            <a-form-model-item prop="enterpriseId" label="公司">
              <a-select
                placeholder="请选择公司"
                style="width: 100%"
                show-search
                :filter-option="filterOption"
                v-model="form.enterpriseId"
              >
                <a-select-option v-for="item in enterlist" :key="item.id">
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item ref="name" prop="name" label="项目">
              <a-input
                placeholder="项目不能为空"
                @blur="
                  () => {
                    $refs.name.onFieldBlur()
                  }
                "
                v-model="form.name"
              />
            </a-form-model-item>
            <a-form-model-item
              ref="shortName"
              prop="shortName"
              label="项目简称"
            >
              <a-input
                placeholder="项目简称不能为空"
                v-model="form.shortName"
                @blur="
                  () => {
                    $refs.name.onFieldBlur()
                  }
                "
              />
            </a-form-model-item>
            <a-form-model-item prop="districtIds" label="地区">
              <a-cascader
                style="width: 100%"
                v-model="form.districtIds"
                :options="areaData"
                :field-names="{
                  label: 'name',
                  value: 'code',
                  children: 'children',
                }"
                placeholder="请选择地区"
                @change="onChange"
              />
            </a-form-model-item>
            <a-form-model-item ref="area" prop="area" label="项目面积">
              <a-input
                placeholder="项目面积不能为空"
                v-model="form.area"
                @blur="
                  () => {
                    $refs.name.onFieldBlur()
                  }
                "
              />
            </a-form-model-item>
            <a-form-model-item prop="openDate" label="开业日期">
              <a-date-picker v-model="form.openDate" style="width: 100%">
                <template slot="renderExtraFooter"> </template>
              </a-date-picker>
            </a-form-model-item>

            <a-form-model-item
              placeholder="项目地址不能为空"
              style="width: 50%; padding-right: 20px"
              label="项目地址"
              ref="address"
              prop="address"
            >
              <a-input
                @blur="
                  () => {
                    $refs.name.onFieldBlur()
                  }
                "
                v-model="form.address"
              />
            </a-form-model-item>
            <a-form-model-item
              placeholder="项目简介不能为空"
              style="width: 100%"
              label="项目简介"
              ref="description"
              prop="description"
            >
              <a-textarea
                @blur="
                  () => {
                    $refs.name.onFieldBlur()
                  }
                "
                v-model="form.description"
                :auto-size="{ minRows: 3 }"
              />
            </a-form-model-item>
          </a-form-model>
        </div>
      </a-layout-content>
      <a-drawer
        width="360"
        title="添加楼栋"
        :placement="right"
        :closable="false"
        :visible="visibles"
        @close="onclose"
      >
        <a-form-model
          :model="form"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
        >
          <a-form-model-item style="width: 100%" label="楼栋号">
            <a-input v-model="build.name" />
          </a-form-model-item>
          <a-form-model-item style="width: 100%">
            <a-button
              v-show="floorIndex != undefined && visible == true"
              @click="ondelbuild"
              style="
                width: 100%;
                background-color: red;
                border: none;
                color: #fff;
              "
            >
              删除楼栋
            </a-button>
          </a-form-model-item>
          <div v-if="tit == 'one'">
            <a-form-model-item style="width: 100%" label="楼层数">
              <div class="flex-d-row" style="margin-bottom: 25px">
                <div class="input-after">地面</div>
                <a-input v-model="number1" />
                <div class="input-before">层</div>
              </div>
              <div class="flex-d-row">
                <div class="input-after">地下</div>
                <a-input v-model="number2" />
                <div class="input-before">层</div>
              </div>
            </a-form-model-item>
            <a-form-model-item style="width: 100%">
              <a-button
                @click="onAdd"
                style="
                  width: 100%;
                  background-color: orange;
                  border: none;
                  color: #fff;
                "
              >
                生成
              </a-button>
            </a-form-model-item>
          </div>
          <div v-else>
            <div
              class="flex-d-row"
              style="padding: 16px 0; justify-content: space-between"
            >
              <span>楼层</span>
              <span @click="onwit" style="color: #0933a3">重新生成</span>
            </div>
            <div class="draw-floor">
              <div style="padding: 16px; border-bottom: 1px solid #aaaaaa">
                共<span>{{ build.floors.length }}</span
                >层
              </div>
              <div class="draw-floor-body">
                <div>
                  <div
                    v-for="(item, index) in build.floors"
                    :key="item"
                    class="floor-body-div"
                  >
                    <span @click.prevent="drawfloor">{{ item.name }}</span>
                    <div class="body-div" v-if="params.total == 0">
                      <a-icon @click="onflooredit" type="edit" />
                      <a-icon
                        v-if="form.id == ''"
                        style="margin-left: 10px"
                        @click="onfloordele(item.id, index)"
                        type="delete"
                      />
                      <a-icon
                        v-else
                        style="margin-left: 10px"
                        @click="onfloordele(item.id, index)"
                        type="delete"
                      />
                    </div>
                    <div v-else class="body-div">
                      <a-input style="width: 100px" v-model="floorform.name" />
                      <button
                        v-if="form.id == ''"
                        @click="onSavefloor(index)"
                        class="btn"
                      >
                        确定
                      </button>
                      <button v-else @click="onSavefloor(item.id)" class="btn">
                        确定
                      </button>
                      <a-icon
                        v-if="form.id == ''"
                        style="margin-left: 10px"
                        @click="onfloordele(item.id, index)"
                        type="delete"
                      />
                      <a-icon
                        v-else
                        style="margin-left: 10px"
                        @click="onfloordele(item.id, index)"
                        type="delete"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </a-form-model>
        <div class="save-draw">
          <a-button type="primary" @click="handleOk"> 保存 </a-button>
          <a-button @click="onclose" style="margin-left: 30px"> 取消 </a-button>
        </div>
      </a-drawer>
      <!-- 楼层 -->
      <a-layout-content style="background: #fff; margin: 24px 0; height: 162px">
        <div class="content-title">
          <span>楼栋管理</span>
        </div>
        <div
          style="overflow: auto; overflow-y: hidden"
          class="content-body flex-d-row"
        >
          <div
            v-for="(item, index) in form.buildings"
            :key="item.name"
            style="line-height: 25px; margin-right: 10px"
            @click="Addbuild(item, index)"
            class="body-building flex-d-column"
          >
            <span>{{ item.name }}</span>
            <span>{{ item.floors.length + '层' }}</span>
          </div>
          <div @click="Addbuild(undefined)" class="body-building">+</div>
        </div>
      </a-layout-content>
      <!-- 图片 -->
      <a-layout-content style="background: #fff; height: 217px">
        <div class="content-title">
          <span>图片</span>
        </div>
        <div class="content-body">
          <div class="clearfix">
            <a-upload
              action=""
              list-type="picture-card"
              :file-list="logo"
              @preview="handlePreview"
              :customRequest="handleChange"
              @change="handleChanges"
            >
              <div v-if="logo.length < 5">
                <a-icon type="plus" />
                <div class="ant-upload-text">Upload</div>
              </div>
            </a-upload>
            <a-modal
              :visible="previewVisible"
              :footer="null"
              @cancel="handleCancel"
            >
              <img alt="example" style="width: 100%" :src="previewImage" />
            </a-modal>
          </div>
        </div>
      </a-layout-content>
      <!-- 扩展信息 -->
      <!-- <a-layout-content
        :style="{
          background: '#fff',
          margin: 0,
        }"
      >
        <div class="content-title">
          <span>扩展信息</span>
          <a-icon
            v-if="extension == 0"
            @click="
              () => {
                this.extension = 1;
              }
            "
            style="cursor: pointer;"
            type="down"
          />
          <a-icon
            v-else
            @click="
              () => {
                this.extension = 0;
              }
            "
            style="cursor: pointer;"
            type="up"
          />
        </div>
        <div v-show="extension == 1">a</div>
      </a-layout-content> -->
    </a-layout>
    <a-layout-content
      v-if="visible == true"
      style="
        background-color: #fff;
        line-height: 60px;
        margin: 0;
        width: 100%;
        height: 60px;
        text-align: center;
      "
    >
      <div>
        <a-button html-type="submit" type="primary" @click="onSubmit">
          提交
        </a-button>
        <a-button @click="onClose" style="margin-left: 40px"> 取消 </a-button>
      </div>
    </a-layout-content>
  </a-layout>
</template>
<script>
const columns = [
  {
    title: '项目名称',
    dataIndex: 'name',
  },
  {
    title: '项目简称',
    dataIndex: 'shortName',
    scopedSlots: { customRender: 'shortName' },
  },
  {
    title: '项目面积(m²)',
    dataIndex: 'area',
  },
  {
    title: '开业时间',
    dataIndex: 'openDate',
  },
  {
    title: '项目地址',
    dataIndex: 'address',
  },
  {
    title: '状态',
    dataIndex: 'state',
    scopedSlots: { customRender: 'status' },
  },
  {
    title: '操作',
    dataIndex: 'id',
    scopedSlots: { customRender: 'action' },
  },
]
function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  })
}
const OSS = require('ali-oss')
let client
import { imageMixin } from '../../../mixins'
import http from '../../../http'
import { nanoid } from 'nanoid'

export default {
  mixins: [imageMixin],
  data() {
    return {
      areaData: [],
      previewVisible: false,
      previewImage: '',
      number1: '',
      number2: '',
      num1: [],
      num2: [],
      floorIndex: undefined,
      text: '',
      file: [],
      tit: 'one',
      extension: 0,
      columns,
      title: '',
      loading: false,
      visible: false,
      visibles: false,
      labelCol: { span: 12 },
      wrapperCol: { span: 24 },
      logvisible: false,
      params: {
        current: 1,
        pageSize: 9,
        'cp.name': {
          value: '',
          op: '%like%',
        },
      },
      textlist: ['项目名称', '所在地区'],
      expand: false,
      fom: this.$form.createForm(this, { name: 'advanced_search' }),
      total: 0,
      treeData: [],
      enterlist: [],
      districtlist: [],
      projectlist: [],
      selectedRowKey: [],
      Id: '',
      build: {
        name: '',
        floors: [],
      },
      floorform: {
        id: '',
        name: '',
      },
      key: '',
      buildingId: '',
      permissionlist: [],
      packagelist: {
        packages: [],
        total: 0,
        usage: 0,
      },
      res: {
        credit: {
          securityToken: '',
          accessKeySecret: '',
          accessKeyId: '',
        },
        region: '',
      },
      logo: [],
      form: {
        id: '',
        typeId: '',
        districtId: [],
        districtIds: [],
        enterpriseId: '',
        name: '',
        description: '',
        shortName: '',
        address: '',
        area: '',
        openDate: '',
        state: '',
        logo: '',
        district: 25,
        buildings: [],
      },
      rules: {
        name: [
          {
            required: true,
            message: '铺位号不能为空',
            trigger: 'blur',
          },
        ],
        enterpriseId: [
          {
            required: true,
            message: '公司不能为空',
            trigger: 'change',
          },
        ],
        shortName: [
          {
            required: true,
            message: '项目简称不能为空',
            trigger: 'blur',
          },
        ],
        districtIds: [
          {
            required: true,
            message: '地区简称不能为空',
            trigger: 'change',
          },
        ],
        area: [{ validator: this.validateArea, trigger: 'change' }],
        openDate: [
          {
            required: true,
            message: '开业时间不能为空',
            trigger: 'change',
          },
        ],
        address: [
          {
            required: true,
            message: '项目地址不能为空',
            trigger: 'blur',
          },
        ],
        description: [
          {
            required: true,
            message: '简介不能为空',
            trigger: 'blur',
          },
        ],
      },
    }
  },
  filters: {},
  methods: {
    validateArea(rule, value, callback) {
      if (value === '') {
        callback(new Error('请输入面积'))
      } else if (!this.patternNumbers(value)) {
        callback(new Error('面积支持最多10位整数,两位小数的数字'))
      } else {
        callback()
      }
    },
    patternNumbers(val) {
      return /^((0)|([1-9][0-9]{0,9})|(([0]\.\d{1,2}|[1-9][0-9]{0,9}\.\d{1,2})))$/.test(
        val
      )
    },
    handleSearch(e) {
      //   console.log(this.params);
      e.preventDefault()
      this.params.current = 1
      this.fom.validateFields((error, values) => {
        // console.log(values);
        this.params['name'].value = values.项目名称
      })
      if (
        JSON.parse(localStorage.getItem('store-userlist')).permissions.indexOf(
          '/property/project:查询当前'
        ) > -1
      ) {
        this.getProject()
      } else if (
        JSON.parse(localStorage.getItem('store-userlist')).permissions.indexOf(
          '/property/project:查询全部'
        ) > -1
      ) {
        this.getProject1()
      }
    },

    handleReset() {
      this.fom.resetFields()
    },
    onvalue(e) {
      //   console.log(e);
      this.form.typeId = e
    },
    onwit() {
      this.tit = 'one'
      this.build.floors = []
      this.num1 = []
      this.num2 = []
      this.number1 = this.number2 = ''
    },
    handleCancel() {
      this.previewVisible = false
    },
    async handlePreview(file) {
      //   console.log(file);
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj)
      }
      this.previewImage = file.url || file.preview
      this.previewVisible = true
    },
    handleChanges({ fileList }) {
      if (this.logo.length > fileList.length) {
        this.logo = fileList
        this.form.logo = JSON.stringify(fileList)
      }
    },
    async getsts() {
      try {
        const res = await http.getsts()
        // console.log(res);
        this.res.credit.securityToken = res.data.data.credit.securityToken
        this.res.credit.accessKeySecret = res.data.data.credit.accessKeySecret
        this.res.credit.accessKeyId = res.data.data.credit.accessKeyId
        this.res.region = res.data.data.region
        client = new OSS({
          region: this.res.region,
          accessKeyId: this.res.credit.accessKeyId,
          accessKeySecret: this.res.credit.accessKeySecret,
          stsToken: this.res.credit.securityToken,
          // 填写Bucket名称。
          bucket: 'sccncloud2',
        })
      } catch (e) {
        console.error(e)
      }
    },
    handleChange(file) {
      var data = file.file
      this.putObject(client, nanoid(), file.file.name.split('.')[1], data)
    },
    async putObject(client, id, name, data) {
      try {
        const result = await client.put(`sccn/${id}.${name}`, data)
        const url = client.signatureUrl(result.name)
        var a = [result]
        a.forEach(item => {
          this.logo.push({
            bucket: 'sccncloud2',
            name: item.name,
            uid: id,
            status: 'done',
            url: url,
          })
          this.form.logo = JSON.stringify(this.logo)
        })
      } catch (e) {
        console.log(e)
      }
    },
    drawfloor() {
      this.total = 0
    },
    //增加楼栋
    Addbuild(e, index) {
      if (index == undefined) {
        this.tit = 'one'
        this.floorIndex = undefined
        this.total = 1
        this.build = {
          name: '',
          floors: [],
        }
      } else {
        this.buildingId = e.id
        this.build = e
        this.floorIndex = index
        this.tit = 'two'
        this.total = 0
      }
      this.visibles = true
    },
    onAdd() {
      if (this.number1 == '' && this.number2 == '') {
        this.$message.error('请先输入楼层数和楼栋号')
        return
      }
      this.total = 0
      for (let index = 1; index <= this.number1; index++) {
        this.num1.push({ name: index })
      }
      for (let index = 1; index <= this.number2; index++) {
        this.num2.push({ name: 'B' + index })
      }
      this.build.floors = this.num1.concat(this.num2)
      //   console.log(this.build);
      setTimeout(() => {
        this.tit = 'two'
      }, 500)
    },
    onflooredit() {
      this.total = 1
    },
    onfloordele(id, index) {
      if (this.form.id == '') {
        const a = this.build.floors
        a.splice(index, 1)
        this.build.floors = a
        // console.log(this.build.floors);
        this.total = 0
      } else {
        if (id) {
          this.deleteFloor(id)
        } else {
          this.build.floors.splice(index, 1)
        }
      }
    },
    onSavefloor(index) {
      if (this.floorform.name != '') {
        if (this.form.id == '') {
          this.build.floors[index].name = this.floorform.name
          //   console.log(this.build);
        } else {
          this.build.floors[0].name = this.floorform.name
          this.floorform.id = index
          this.putFloor(this.floorform)
          //   console.log(this.floorform);
        }
        this.total = 0
      }
    },
    handleOk() {
      if (this.floorIndex == undefined) {
        this.form.buildings.push(this.build)
      } else {
        this.form.buildings.splice(this.floorIndex, 1, this.build)
      }
      this.visibles = false
      this.tit = 'one'
      this.num1 = []
      this.num2 = []
      this.number1 = this.number2 = ''
    },
    onclose() {
      this.tit = 'one'
      this.num1 = []
      this.num2 = []
      this.number1 = this.number2 = ''
      this.build = {
        name: '',
        floor: [],
      }
      this.visibles = false
    },

    onClose() {
      this.logo = []
      this.form = {
        id: '',
        typeId: '',
        districtId: [],
        districtIds: [],
        enterpriseId: '',
        name: '',
        description: '',
        shortName: '',
        address: '',
        area: '',
        openDate: '',
        state: '',
        logo: '',
        district: 25,
        buildings: [],
      }
      this.visible = false
      this.Id = ''
    },
    onSubmit() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          //   console.log("submit!", this.form);
          if (this.Id == '') {
            this.AddProject(this.form)
          } else if (this.Id != '') {
            this.putProject(this.form)
          }
        } else {
          this.$message.error('请检查表单输入项')
          return false
        }
      })
    },
    // 删除
    onDetele() {
      const that = this
      this.$confirm({
        title: '确认要删除该项目么?',
        okText: '是',
        cancelText: '否',
        onOk() {
          setTimeout(() => {
            that.deleteProject()
          }, 100)
        },
        onCancel() {
          console.log()
        },
      })
    },
    async deleteProject() {
      try {
        const res = await http.deleteProject(this.selectedRowKey)
        const { success, msg } = res.data
        if (success) {
          this.getconfiguserrest()
          this.$message.success(msg)
        } else {
          this.$message.warning(msg)
        }
        this.timer()
      } catch (ex) {
        this.$message.error(ex)
        console.log('error:' + ex)
      }
    },
    // 编辑
    onEdit(item) {
      this.Id = item.id
      this.form.id = item.id
      //   console.log(this.form.id);
      this.visible = true
      this.title = '编辑'
      this.getProjectId(this.form.id)
    },
    onchangestate(e) {
      //   console.log(e);
      if (e.state == 1) {
        this.form.state = 0
      } else if (e.state == 0) {
        this.form.state = 1
      }
      this.stateProject(e.id, this.form.state)
    },
    async putProject(form) {
      try {
        const res = await http.putProject(form)
        const { success, msg } = res.data
        if (success) {
          this.$message.success('操作成功')
          this.getprojectlist()
          this.onClose()
        } else {
          this.$message.warning(msg)
        }
        this.timer()
      } catch (ex) {
        this.$message.error(ex)
      }
    },
    onChange(e) {
      console.log(e)
      //   console.log(this.districtIds);
      this.form.districtId = e[e.length - 1]
    },
    onpagesize(e) {
      this.params.current = e
      this.getProject()
      this.getProject1()
    },
    showDrawer() {
      if (this.packagelist.total == this.packagelist.usage) {
        this.$message.error('您当前套餐,项目数量不足!')
        return
      }
      this.visible = true
      this.title = '新增'
      this.Id = ''
      this.$store.commit('selectKeys')
    },
    async getProject() {
      try {
        const res = await http.getProject(this.params)
        const { success, data } = res.data
        if (success) {
          this.projectlist = data.projects.records
          this.total = data.projects.total
          //   console.log(data.projects);
        }
      } catch (ex) {
        console.log('error:', ex)
      }
    },
    //查询全部
    async getProject1() {
      try {
        const res = await http.getProject1(this.params)
        const { success, data } = res.data
        if (success) {
          this.projectlist = data.projects.records
          this.total = data.projects.total
          console.log(data.projects)
        }
      } catch (ex) {
        console.log('error:', ex)
      }
    },
    async getProjectId(id) {
      try {
        const res = await http.getProjectId(id)
        // console.log(res);
        const { success, data } = res.data
        if (success) {
          this.form.id = data.project.id
          this.form.typeId = data.project.typeId
          this.form.districtId = data.project.districtId

          this.form.districtIds = [
            data.project.districtId.substring(0, 2),
            data.project.districtId.substring(0, 4),
            data.project.districtId,
          ]
          this.form.enterpriseId = data.project.enterpriseId
          this.form.name = data.project.name
          this.form.area = data.project.area
          this.form.openDate = data.project.openDate
          this.form.description = data.project.description
          this.form.shortName = data.project.shortName
          this.form.address = data.project.address
          setTimeout(() => {
            if (data.project.logo == null) {
              this.logo = []
            } else {
              this.logo = JSON.parse(data.project.logo)
            }
            var a = []
            this.logo.forEach(item => {
              const url = client.signatureUrl(item.name)
              a.push({
                bucket: 'sccncloud2',
                name: item.name,
                uid: nanoid(),
                status: 'done',
                url: url,
              })
            })
            this.logo = a
            this.form.logo = JSON.stringify(this.logo)
          }, 100)
          this.form.district = data.project.district
          this.form.buildings = data.project.buildings
          if (this.build.floors.length > 0) {
            this.build.floors = this.form.buildings[this.floorIndex].floors
          }
        }
      } catch (ex) {
        console.log('error:', ex)
      }
    },
    // 新增
    async AddProject(form) {
      try {
        const res = await http.AddProject(form)
        const { success, msg } = res.data
        // console.log(1);
        if (success) {
          this.$message.success('操作成功')
          this.getprojectlist()
          this.onClose()
          this.getprojectrest()
        } else {
          this.$message.warning(msg)
        }
        this.timer()
      } catch (ex) {
        this.$message.error(ex)
      }
    },
    async getprojectlist() {
      try {
        const res = await http.getprojectlist()
        const { success, data } = res.data
        if (success) {
          //   console.log(data.projects);
          this.$nextTick(function () {
            this.$store.state.projects = data.projects
          })
          //   this.$router.go(0);
        }
      } catch (ex) {
        console.log('error:' + ex)
      }
    },
    async getProjecttype() {
      try {
        const res = await http.getProjecttype()
        const { success, data } = res.data
        if (success) {
          this.treeData = data.type
        }
      } catch (ex) {
        console.log('error:', ex)
      }
    },
    async getenterpriselist() {
      try {
        const res = await http.getenterpriselist()
        const { success, data } = res.data
        if (success) {
          this.enterlist = data.enterprises
        }
      } catch (ex) {
        console.log('error:', ex)
      }
    },
    //项目数量
    async getprojectrest() {
      try {
        const res = await http.getprojectrest()
        const { success, data } = res.data
        if (success) {
          //   console.log(data);
          this.packagelist = data
        }
      } catch (ex) {
        console.log('error:', ex)
      }
    },
    async stateProject(id, state) {
      try {
        const res = await http.stateProject(id, state)
        const { success, msg } = res.data
        if (success) {
          this.$message.success('操作成功')
        } else {
          this.$message.warning(msg)
        }
        this.timer()
      } catch (ex) {
        console.log('error:', ex)
        this.$message.error(ex)
      }
    },
    //楼层async
    // 删除
    async deleteFloor(key) {
      try {
        const res = await http.deleteFloor(key)
        const { success, msg } = res.data
        if (success) {
          this.getProjectId(this.form.id)
          this.$message.success('操作成功')
          setTimeout(() => {
            this.floorform.name = ''
          }, 2000)
        } else {
          this.$message.warning(msg)
        }
      } catch (ex) {
        console.log('error:' + ex)
        this.$message.error(ex)
      }
    },
    ondelbuild() {
      this.deleteBuilding(this.buildingId)
    },
    async deleteBuilding(key) {
      try {
        const res = await http.deleteBuilding(key)
        const { success, msg } = res.data
        if (success) {
          this.getProjectId(this.form.id)

          this.$message.success('操作成功')
        } else {
          this.$message.warning(msg)
        }
        this.visibles = false
      } catch (ex) {
        console.log('error:' + ex)
        this.$message.error(ex)
      }
    },
    async putFloor(form) {
      try {
        const res = await http.putFloor(form)
        const { success, msg } = res.data
        if (success) {
          //   console.log(this.build.floors);
          this.$message.success('操作成功')
          setTimeout(() => {
            this.floorform.name = ''
          }, 2000)
        } else {
          this.$message.warning(msg)
        }
      } catch (ex) {
        console.log('error:', ex)
        this.$message.error(ex)
      }
    },
    async getdistrict() {
      const res = await http.getdistrict()
      const { success, data } = res.data
      if (success) {
        // console.log(data);
        this.districtlist = data.districts
        this.districtlist.forEach(item => {
          item.children.forEach(item => {
            if (item.children == '') {
              item.children = undefined
            } else {
              item.children.forEach(item => {
                if (item.children == '') {
                  item.children = undefined
                } else {
                  item.children.forEach(item => {
                    if (item.children == '') {
                      item.children = undefined
                    }
                  })
                }
              })
            }
          })
        })
      }
    },
    handleClickRow(value) {
      return {
        on: {
          dblclick: () => {
            this.display = 'none'
            this.dis = 'block'
            // console.log(value);
            this.thing = value
          },
        },
      }
    },
    timer() {
      return setTimeout(() => {
        if (
          JSON.parse(
            localStorage.getItem('store-userlist')
          ).permissions.indexOf('/property/project:查询当前') > -1
        ) {
          this.getProject()
        } else if (
          JSON.parse(
            localStorage.getItem('store-userlist')
          ).permissions.indexOf('/property/project:查询全部') > -1
        ) {
          this.getProject1()
        }
      }, 500)
    },
    beforeDestroy() {
      clearInterval(this.timer)
      this.timer = null
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      )
    },
    rowClassName(record, index) {
      let className = 'light'
      if (index % 2 === 1) className = 'dark'
      return className
    },
    onout() {
      this.$router.push({ name: 'login' })
    },
  },
  async mounted() {
    const that = this
    console.log('haahaha------')
    await import('@/assets/pca-code.json').then(res => {
      that.areaData = res.default
    })
    this.getProjecttype()
    this.getenterpriselist()
    this.getdistrict()
    this.getsts()
    this.getprojectrest()
    this.permissionlist = JSON.parse(
      localStorage.getItem('store-userlist')
    ).permissions
    var userlist = JSON.parse(localStorage.getItem('store-userlist'))
    if (userlist.permissions.indexOf('/property/project:查询当前') > -1) {
      this.getProject()
    } else if (
      userlist.permissions.indexOf('/property/project:查询全部') > -1
    ) {
      this.getProject1()
    }
    if (userlist == null) {
      this.logvisible = true
    }
    if (this.$route.query.visible == 'true') {
      this.showDrawer()
    }
  },
  computed: {
    count() {
      return this.expand ? 11 : 7
    },
    rowSelection() {
      return {
        onChange: (selectedRowKey, selectedRows) => {
          this.selectedRowKey = selectedRowKey
          //   console.log("selectedRowKey:" + this.selectedRowKey);
          console.log('selectedRows:', selectedRows)
        },
      }
    },
  },
}
</script>
<style lang="less" scoped>
.search-wrap {
  width: 100%;
  /* background: #999; */
  //   box-shadow: 0 6px 12px 0 rgb(0 0 0 / 4%);
  border-radius: 3px;
  position: relative;
  min-height: 658px;
  height: 100%;
  padding: 10px 0 6px;
  margin-bottom: 15px;
}
.btn {
  width: 40px;
  height: 30px;
  font-size: 12px;
  color: #fff;
  border: none;
  line-height: 0;
  background-color: rgb(2, 10, 88);
}
.flex-d-row {
  display: flex;
  flex-direction: row;
}
.flex-d-column {
  display: flex;
  flex-direction: column;
}
.ant-layout {
  height: 100%;
}
.ant-pagination {
  float: right;
  margin-top: 10px;
}
.ant-page-header {
  padding: 0;
}
.Enter-top {
  display: flex;
  flex-direction: row;
  padding: 0 24px;
  justify-content: space-between;
  .Enter-top-right {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-right: 15px;
    .top-right-inpot {
      height: 32px;
      font-size: 13px;
      color: orange;
      border-radius: 22px;
      margin: 0 10px;
      border: 1px solid orange;
    }
  }
}
.content-title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  padding: 0 24px;
  border-bottom: 1px solid #eaeaea;
}
.content-body {
  padding: 24px;
  .body-building {
    min-width: 50px;
    line-height: 50px;
    min-height: 50px;
    text-align: center;
    border: 1px dashed #999;
  }
  .body-building:hover {
    cursor: pointer;
    color: rgb(197, 172, 29);
    border: 1px dashed rgb(197, 172, 29);
  }
  .ant-form-item {
    width: 25%;
    margin: 0;
    padding-right: 20px;
  }
}
.input-after,
.input-before {
  height: 32px;
  border: 1px solid #d9d9d9;
  line-height: 32px;
  text-align: center;
}
.save-draw {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  height: 50px;
  line-height: 50px;
  border-top: 1px solid #eaeaea;
}
.draw-floor {
  min-height: 520px;
  background-color: #f0f1f4;
}
.draw-floor-body {
  padding: 16px;
}
.packages {
  display: inline-block;
  font-size: 15px;
  font-weight: 600;
}
.floor-body-div {
  line-height: 50px;
  background-color: #fff;
  height: 50px;
  margin-bottom: 16px;
  position: relative;
  padding: 0 20px;
  .body-div {
    position: absolute;
    right: 20px;
    top: 0px;
    display: none;
  }
}
.floor-body-div > span {
  display: block;
  width: 100%;
  height: 50px;
}
.floor-body-div:hover {
  .body-div {
    display: block;
  }
}
.a-col-top {
  position: absolute;
  right: 0;
  top: 43px;
}
.updata > img {
  border-radius: 4px;
  margin-right: 5px;
  border: 1px dashed #d9d9d9;
  text-align: center;
  height: 102px;
  width: 102px;
}
.input-after {
  width: 60px;
}
.input-before {
  width: 40px;
}
::v-deep .ant-input-affix-wrapper .ant-input:not(:last-child) {
  padding-right: 30px;
  padding-left: 10px;
}
.ant-input {
  border-radius: 0;
  padding-left: 10px;
  color: #000;
}
.content-body > .ant-form-item {
  height: 90px;
}

::v-deep .ant-form-item-label {
  text-align: left;
}
::v-deep .ant-table .dark {
  background-color: #fafafa;
}
::v-deep .ant-table .light {
  background-color: white;
}
::v-deep .ant-table .dark {
  background-color: #fafafa;
}
</style>
